const IconChevronDown = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path
            d="M12 17.274L0.078125 7.73641L0.893439 6.73047L12 15.6153L23.1065 6.73115L23.9218 7.73641L12 17.274Z"
            fill="currentColor"
        />
    </svg>
);

export default IconChevronDown;
